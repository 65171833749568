import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import SurveyList from "../survey/list/SurveyList";
import CreateNewSurvey from "../survey/create/CreateSurveyForm";
import axiosInstance from "../../../utils/axios-instance";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "@toolpad/core";
import { CreateSurveyProps, SurveyResponse } from "../../../types/survey";
import { User } from "../../../types/auth";

const SurveyDashboard: React.FC = () => {
  const [surveys, setSurveys] = useState<SurveyResponse[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [cookies] = useCookies(["novProjectToken"]);
  const navigator = useNavigate();

  // Get user profile
  useEffect(() => {
    const fetchData = async () => {
      const token = cookies.novProjectToken["accessToken"];
      const user: User = await axiosInstance.get("/user/me", { headers: { Authorization: "Bearer " + token } });

      if (user.status === 200) {
        setUserId(user?.data?._id);
      }
    };
    fetchData();
  }, [cookies.novProjectToken]);

  // Get survey data
  useEffect(() => {
    const fetchData = async () => {
      const survey = await axiosInstance.get<SurveyResponse[]>("/survey/" + userId);
      setSurveys(survey.data);
    };
    fetchData();
  }, [userId]);

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleSaveSurvey = async (createSurvey: CreateSurveyProps) => {
    const surveyResponse = await axiosInstance.post<SurveyResponse>("/survey", {
      ...createSurvey,
      creatorId: userId,
      trigger_criteria: [{ hookUrl: "", pattern: "exact" }],
    });
    if (surveyResponse.status === 201) {
      await axiosInstance.post("question", {
        surveyId: surveyResponse.data._id,
        questionDisplay: "What is your feeling?",
        isRequired: true,
        option: {
          optionType: "rating",
        },
        step: 1,
      });
      navigator("/survey/" + surveyResponse.data._id);
    }
  };

  const handleOnClick = async (surveyId: string) => {
    navigator("/survey/" + surveyId);
  };

  return (
    <DashboardLayout>
      <Container>
        <Box my={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h5">Survey Dashboard</Typography>
            <Box>
              <Button variant="contained" color="primary" onClick={() => handleOpenForm()}>
                Create New Survey
              </Button>
            </Box>
          </Box>
          <Box mt={4}>
            <SurveyList surveys={surveys} onClick={handleOnClick} />
          </Box>
        </Box>
        <CreateNewSurvey open={isFormOpen} onClose={handleCloseForm} onSave={handleSaveSurvey} />
      </Container>
    </DashboardLayout>
  );
};

export default SurveyDashboard;
