// components/TestimonialsSection.tsx
import React from "react";
import { Box, Typography, Container, Grid, Paper } from "@mui/material";

const testimonials = [
  { name: "Jane Doe", feedback: "This platform made my survey process seamless!" },
  { name: "John Smith", feedback: "Highly recommend for anyone needing quick insights." },
];

const TestimonialsSection: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <Typography variant="h4" textAlign="center" gutterBottom>
          What Our Users Say
        </Typography>
        <Grid container spacing={4} mt={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper sx={{ p: 4 }}>
                <Typography variant="body1" mb={2}>
                  "{testimonial.feedback}"
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold">
                  - {testimonial.name}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TestimonialsSection;
