import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";

const HeroSection: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        py: 8,
        textAlign: "center",
      }}>
      <Container maxWidth="md">
        <Typography variant="h3" fontWeight="bold" gutterBottom>
          Empower Your Surveys
        </Typography>
        <Typography variant="h6" mb={3}>
          Effortlessly create, share, and analyze surveys with our easy-to-use platform.
        </Typography>
        <Button variant="contained" color="secondary" size="large">
          Get Started for Free
        </Button>
      </Container>
    </Box>
  );
};

export default HeroSection;
