import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axios-instance";
import { Report } from "../../../../types/report";
import { DashboardLayout } from "@toolpad/core";
import { GaugeContainer, GaugeValueArc, GaugeReferenceArc, useGaugeState } from "@mui/x-charts/Gauge";

interface ReportSummaryProps {}

const ReportSummary: React.FC<ReportSummaryProps> = () => {
  const param = useParams();
  const [response, setResponse] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const surveyId = param.surveyId;

  useEffect(() => {
    const fetchDate = async () => {
      const result = await axiosInstance.get<Report[]>("/response/" + surveyId);
      setResponse(result.data);
      const totalScore = result.data.length * 5;
      const currentScore = result.data.reduce((acc, cur) => acc + Number(cur?.responses?.[0]?.response_value || 0), 0);
      setTotalScore(Math.round(currentScore * (100 / totalScore)));
    };

    fetchDate();
  }, [surveyId]);

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      // No value to display
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="red" />
        <path d={`M ${cx} ${cy} L ${target.x} ${target.y}`} stroke="red" strokeWidth={3} />
      </g>
    );
  }

  return (
    <DashboardLayout>
      <Container>
        <Box my={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h5">Report</Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box>
              <GaugeContainer width={400} height={400} startAngle={-100} endAngle={100} value={totalScore}>
                <GaugeReferenceArc />
                <GaugeValueArc />
                <GaugePointer />
              </GaugeContainer>
            </Box>
            <Box>
              <Typography variant="h5"> Total score : {totalScore}</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default ReportSummary;
