// components/PricingSection.tsx
import React from "react";
import { Box, Typography, Container, Grid, Card, CardContent, Button } from "@mui/material";

const plans = [
  { name: "Free", price: "$0", features: ["5 surveys", "100 responses", "Basic analytics"] },
  { name: "Pro", price: "$29/month", features: ["Unlimited surveys", "10k responses", "Advanced analytics"] },
];

const PricingSection: React.FC = () => {
  return (
    <Box sx={{ py: 8, bgcolor: "grey.100" }}>
      <Container>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Choose Your Plan
        </Typography>
        <Grid container spacing={4} mt={4}>
          {plans.map((plan, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" textAlign="center">
                    {plan.name}
                  </Typography>
                  <Typography variant="h6" textAlign="center" mt={1} mb={2}>
                    {plan.price}
                  </Typography>
                  <ul>
                    {plan.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                  <Box textAlign="center" mt={2}>
                    <Button variant="contained" color="primary">
                      Get Started
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PricingSection;
