import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/container/home";
import { Auth } from "./pages/container/auth";
import { NPS } from "./sdk/nps/NPS";
import DashboardRouter from "./Dashboard-Route";
import { AppProvider, type Navigation } from "@toolpad/core/AppProvider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";

function App() {
  const NAVIGATION: Navigation = [
    {
      kind: "header",
      title: "Main items",
    },
    {
      segment: "dashboard",
      title: "Dashboard",
      icon: <DashboardIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Analytics",
    },
    {
      segment: "reports",
      title: "Reports",
      icon: <BarChartIcon />,
    },
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/account" element={<Auth />} />
        <Route path="/nps" element={<NPS />} />
      </Routes>
      <AppProvider
        navigation={NAVIGATION}
        branding={{
          logo: <img src="/proterate-logo.png" alt="MUI logo" />,
          title: "proterate",
        }}>
        <DashboardRouter />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
