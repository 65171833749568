// components/LoginForm.tsx
import React from "react";
import AuthForm from "./AuthForm";

const LoginForm: React.FC<{ onSubmit: (formData: Record<string, string>) => void }> = ({ onSubmit }) => {
  const fields = [
    { name: "email", label: "Email", type: "email" },
    { name: "password", label: "Password", type: "password" },
  ];

  return <AuthForm title="Login" buttonText="Login" onSubmit={onSubmit} fields={fields} />;
};

export default LoginForm;
