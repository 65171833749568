import React from "react";
import { Box, Typography } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box sx={{ bgcolor: "primary.main", color: "white", py: 4, textAlign: "center" }}>
      <Typography variant="body2">&copy; 2024 SurveyApp. All rights reserved.</Typography>
    </Box>
  );
};

export default Footer;
