import React from "react";
import { Box, Card, CardHeader, Grid2, CardActionArea, CardContent, Switch } from "@mui/material";
import { SurveyResponse } from "../../../../types/survey";

interface SurveyReportListProps {
  surveys: SurveyResponse[];
  onClick: (id: string) => void;
}

const SurveyReportList: React.FC<SurveyReportListProps> = ({ surveys, onClick }) => {
  return (
    <Box>
      <Grid2 container spacing={2}>
        {surveys.map((survey) => (
          <Grid2 size={{ xs: 12, md: 12, lg: 6, xl: 4 }}>
            <Card sx={{ width: "100%", minWidth: "370px", cursor: "pointer" }}>
              <CardActionArea
                onClick={() => {
                  onClick(survey._id);
                }}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <CardHeader title={survey.title} subheader={survey.description} sx={{ minHeight: "88px" }} />
                    <Switch checked={survey.is_active} />
                  </Box>
                </Box>
                <CardContent>{survey.creator_id.name}</CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default SurveyReportList;
