// pages/AccountPage.tsx
import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Snackbar, Alert, AlertTitle } from "@mui/material";
import LoginForm from "./components/LoginForm";
import SignupForm from "./components/SignupForm";
import axiosInstance from "../../../utils/axios-instance";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const [cookies, setCookie] = useCookies(["novProjectToken"]);

  const navigator = useNavigate();

  const handleLoginSubmit = async (formData: Record<string, string>) => {
    const token = await axiosInstance.post<{ data: string }>("/auth/login", formData).catch((e) => {
      setIsLoginFailed(true);
      return { data: "" };
    });
    setCookie("novProjectToken", token?.data);
    navigator("/dashboard");
  };

  const handleSignupSubmit = async (formData: Record<string, string>) => {
    const response = await axiosInstance.post("/user", { ...formData, role: "admin" });
    if (response.status === 201) {
      setIsSignUpSuccess(true);
    }
  };

  useEffect(() => {
    if (cookies.novProjectToken) {
      navigator("/dashboard");
    }
  }, [cookies.novProjectToken, navigator]);

  return (
    <>
      {isLoginFailed && (
        <Alert variant="filled" severity="error">
          <AlertTitle>Error</AlertTitle>
          Username or password is incorrect.
        </Alert>
      )}
      {isSignUpSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
          open={isSignUpSuccess}
          onClose={() => {
            setIsSignUpSuccess(false);
          }}
          message="Signup success"
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          bgcolor: "grey.100",
          px: 2,
        }}>
        <Box
          sx={{
            maxWidth: 500,
            width: "100%",
            bgcolor: "white",
            boxShadow: 3,
            borderRadius: 2,
            p: 4,
          }}>
          {isLogin ? <LoginForm onSubmit={handleLoginSubmit} /> : <SignupForm onSubmit={handleSignupSubmit} />}
          <Box textAlign="center" mt={2}>
            <Typography variant="body2">{isLogin ? "Don't have an account?" : "Already have an account?"}</Typography>
            <Button variant="text" onClick={() => setIsLogin(!isLogin)}>
              {isLogin ? "Sign Up" : "Login"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
