import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import CreateNewSurvey from "../survey/create/CreateSurveyForm";
import axiosInstance from "../../../utils/axios-instance";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "@toolpad/core";
import { CreateSurveyProps, SurveyResponse } from "../../../types/survey";
import { User } from "../../../types/auth";
import SurveyReportList from "./list/SurveyReportList";

const SurveyReport: React.FC = () => {
  const [surveys, setSurveys] = useState<SurveyResponse[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [cookies] = useCookies(["novProjectToken"]);
  const navigator = useNavigate();

  // Get user profile
  useEffect(() => {
    const fetchData = async () => {
      const token = cookies.novProjectToken["accessToken"];
      const user: User = await axiosInstance.get("/user/me", { headers: { Authorization: "Bearer " + token } });

      if (user.status === 200) {
        setUserId(user?.data?._id);
      }
    };
    fetchData();
  }, [cookies.novProjectToken]);

  // Get survey data
  useEffect(() => {
    const fetchData = async () => {
      const survey = await axiosInstance.get<SurveyResponse[]>("/survey/" + userId);
      setSurveys(survey.data);
    };
    fetchData();
  }, [userId]);

  const handleOnClick = async (surveyId: string) => {
    navigator("/report/" + surveyId);
  };

  return (
    <DashboardLayout>
      <Container>
        <Box my={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h5">Survey Report Dashboard</Typography>
          </Box>
          <Box mt={4}>
            <SurveyReportList surveys={surveys} onClick={handleOnClick} />
          </Box>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default SurveyReport;
