// components/FeaturesSection.tsx
import React from "react";
import { Box, Typography, Container, Grid, Card, CardContent } from "@mui/material";

const features = [
  { title: "Customizable Surveys", description: "Design surveys to match your needs." },
  { title: "Advanced Analytics", description: "Gain insights with real-time data." },
  { title: "Easy Sharing", description: "Distribute surveys via links or embeds." },
];

const FeaturesSection: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Features You'll Love
        </Typography>
        <Grid container spacing={4} mt={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ textAlign: "center", p: 2 }}>
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesSection;
