// components/HowItWorksSection.tsx
import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";

const steps = [
  { step: "1", description: "Create your survey with our intuitive builder." },
  { step: "2", description: "Share your survey link with your audience." },
  { step: "3", description: "Analyze responses and gain actionable insights." },
];

const HowItWorksSection: React.FC = () => {
  return (
    <Box sx={{ bgcolor: "grey.100", py: 8 }}>
      <Container>
        <Typography variant="h4" textAlign="center" gutterBottom>
          How It Works
        </Typography>
        <Grid container spacing={4} mt={4} justifyContent="center">
          {steps.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} textAlign="center">
              <Box
                sx={{
                  bgcolor: "secondary.main",
                  color: "white",
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
                }}>
                <Typography variant="h6" fontWeight="bold">
                  {item.step}
                </Typography>
              </Box>
              <Typography variant="body1">{item.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;
