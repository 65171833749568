import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  DialogContent,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DashboardLayout } from "@toolpad/core";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axios-instance";
import { SurveyResponse } from "../../../../types/survey";
import { customIcons, IconContainer, StyledRating } from "../../../../sdk/nps/NPS";

interface CreateNewSurveyProps {}

const EditSurvey: React.FC<CreateNewSurveyProps> = () => {
  const param = useParams();
  const surveyId = param.surveyId;
  const [survey, setSurvey] = useState<SurveyResponse>({
    _id: "",
    creator_id: {
      _id: "",
      name: "",
      email: "",
      role: "",
    },
    is_active: true,
    title: "",
    description: "",
    trigger_criteria: [
      {
        hookUrl: "",
        pattern: "",
      },
    ],
    question: [
      {
        question_id: [
          {
            _id: "",
            question_display: "",
            is_require: false,
            option: {
              option_choices: [""],
              _id: "",
            },
          },
        ],
        step: "",
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const surveyResponse = await axiosInstance.get<SurveyResponse>("/survey/id/" + surveyId);
      setSurvey(surveyResponse.data);
    };

    fetchData();
  }, [surveyId]);

  const onSurveyTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    setSurvey((prev) => ({ ...prev, title }));
  };

  const onSurveyDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const description = e.target.value;
    setSurvey((prev) => ({ ...prev, description }));
  };

  const onSurveyHookUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hookUrl = e.target.value;
    let trigger_criteria = survey.trigger_criteria;
    if (trigger_criteria.length < 1) {
      trigger_criteria.push({ hookUrl: "", pattern: "exact" });
    }

    trigger_criteria[0].hookUrl = hookUrl;
    setSurvey((prev) => ({ ...prev, trigger_criteria }));
  };

  const onSurveyQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const questionDisplay = e.target.value;
    let question = survey.question;
    question[0].question_id[0].question_display = questionDisplay;
    setSurvey((prev) => ({ ...prev, question }));
  };
  const onSurveyEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const surveyEnabled = e.target.checked;
    setSurvey((prev) => ({ ...prev, is_active: surveyEnabled }));
  };

  const onSurveySave = async () => {
    const updateSurvey = {
      ...survey,
    };
    await axiosInstance.patch("/survey/" + survey._id, updateSurvey);
  };

  return (
    <DashboardLayout>
      <Container>
        <Box>
          <Box my={2} display="flex" flex="row" justifyContent="space-between">
            <Typography variant="h5">Edit survey</Typography>
            <Switch checked={survey?.is_active} onChange={onSurveyEnabledChange} />
          </Box>
          <Box my={2}>
            <TextField label="Description" value={survey?.title} fullWidth required onChange={onSurveyTitleChange} />
          </Box>
          <Box my={2}>
            <TextField
              label="Description"
              value={survey?.description}
              fullWidth
              required
              multiline
              rows={3}
              onChange={onSurveyDescriptionChange}
            />
          </Box>
          <Box my={2}>
            <Box my={2}>Trigger criteria</Box>
            <Box my={2}>
              <TextField
                label="url"
                value={survey?.trigger_criteria?.[0]?.hookUrl}
                fullWidth
                required
                onChange={onSurveyHookUrlChange}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">contained</InputAdornment>,
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            my={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="500px"
            sx={{ background: "rgba(0, 0, 0, 0.5)" }}>
            <Card sx={{ width: "400px", padding: "16px" }}>
              <TextField
                label="Display"
                value={survey.question?.[0]?.question_id?.[0]?.question_display}
                onChange={onSurveyQuestionChange}
                fullWidth
                required
              />
              <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                <StyledRating
                  name="highlight-selected-only"
                  defaultValue={2}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value: number) => customIcons[value].label}
                  highlightSelectedOnly
                  sx={{ gap: "8px" }}
                  size="large"
                />
              </DialogContent>
            </Card>
          </Box>
        </Box>
        <Box my={2} position="sticky" bottom={0} display="flex" justifyContent="flex-end">
          <Button variant="contained" size="medium" onClick={onSurveySave}>
            save
          </Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default EditSurvey;
