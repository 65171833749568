import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { CreateSurveyProps } from "../../../../types/survey";

interface CreateNewSurveyProps {
  open: boolean;
  onClose: () => void;
  onSave: (survey: CreateSurveyProps) => void;
}

const CreateNewSurvey: React.FC<CreateNewSurveyProps> = ({ open, onClose, onSave }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = () => {
    onSave({
      title,
      description,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create survey</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <TextField label="Survey title" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth required />
        </Box>
        <Box my={2}>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            required
            multiline
            rows={3}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewSurvey;
