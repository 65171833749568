// components/SignupForm.tsx
import React from "react";
import AuthForm from "./AuthForm";

const SignupForm: React.FC<{ onSubmit: (formData: Record<string, string>) => void }> = ({ onSubmit }) => {
  const fields = [
    { name: "name", label: "Name", type: "text" },
    { name: "email", label: "Email", type: "email" },
    { name: "password", label: "Password", type: "password" },
    { name: "confirmPassword", label: "Confirm Password", type: "password" },
  ];

  return <AuthForm title="Sign Up" buttonText="Sign Up" onSubmit={onSubmit} fields={fields} />;
};

export default SignupForm;
