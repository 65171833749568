// components/AuthForm.tsx
import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";

interface AuthFormProps {
  title: string;
  buttonText: string;
  onSubmit: (formData: Record<string, string>) => void;
  fields: { name: string; label: string; type: string }[];
}

const AuthForm: React.FC<AuthFormProps> = ({ title, buttonText, onSubmit, fields }) => {
  const [formData, setFormData] = React.useState<Record<string, string>>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%", maxWidth: 400, mx: "auto" }}>
      <Typography variant="h5" textAlign="center" mb={2}>
        {title}
      </Typography>
      {fields.map((field) => (
        <TextField
          key={field.name}
          label={field.label}
          name={field.name}
          type={field.type}
          fullWidth
          margin="normal"
          onChange={handleChange}
          required
        />
      ))}
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {buttonText}
      </Button>
    </Box>
  );
};

export default AuthForm;
