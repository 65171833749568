import axios from "axios";
import { CORE_API_URL } from "../config/internal";

const axiosInstance = axios.create({
  baseURL: CORE_API_URL, // Replace with your API base URL
  timeout: 10000, // Request timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer YOUR_ACCESS_TOKEN", // Replace with your access token if needed
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify the request config before sending the request
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response data before returning it
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
