import "./App.css";
import { Route, Routes } from "react-router-dom";

import SurveyDashboard from "./pages/container/dashboard";
import EditSurvey from "./pages/container/survey/create/EditSurvey";
import SurveyReport from "./pages/container/report";
import ReportSummary from "./pages/container/report/components/ReportSummary";

function DashboardRouter() {
  return (
    <Routes>
      <Route path="/dashboard" element={<SurveyDashboard />} />
      <Route path="/survey/:surveyId" element={<EditSurvey />} />
      <Route path="/reports" element={<SurveyReport />} />
      <Route path="/report/:surveyId" element={<ReportSummary />} />
    </Routes>
  );
}

export default DashboardRouter;
